<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue';

const props = defineProps({
  paginatedReport: {
    type: Object,
    default: () => ({}),
  },
  tableType: {
    type: String,
    default: '',
  },
  isChartAvailable: {
    type: Boolean,
    default: false,
  },
  isStartTimeAvailable: {
    type: Boolean,
    default: false,
  },
  isResultShowen: {
    type: Boolean,
    default: false,
  },
  isChartShowen: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([''])

const tableTypes = ['trips_by_points', 'trips_common'];
const isShowResult = computed(() => {
  return props.paginatedReport && props.paginatedReport.groups && !tableTypes.includes(props.tableType);
});

const isResultShowen = ref(props.isResultShowen);
const isChartShowen = ref(props.isChartShowen);

const toggleChartShow = () => {
  isChartShowen.value = !isChartShowen.value;
  emits('change-chart-showen', isChartShowen.value);
};

</script>

<template>
  <div>
    <skif-checkbox
      v-if="isShowResult"
      v-model="isResultShowen"
      :label="$t('reports.onlyResult')"
    />
    <div
      v-if="isChartAvailable && isStartTimeAvailable"
      class="flex"
    >
      <skif-checkbox v-model="isChartShowen" />
      <span
        class="cursor-pointer select-none font-bold text-annotationColor mb-2"
        @click="toggleChartShow"
      >
        {{ $t('reports.connect') }}
      </span>
    </div>
  </div>
</template>
