<script setup>
import { ref, defineEmits, defineProps } from 'vue';

const props = defineProps({
  isStopShowing: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['change-checked']);

const isStopShowing = ref(props.isStopShowing);

const changeChecked = (event) => {
  emits('change-checked', event);
};
</script>

<template>
  <div
    class="flex align-center justify-start ml-0"
  >
    <skif-checkbox
      v-model="isStopShowing"
      id="checkbox_id"
      class="mr-2"
      show-label
      :label="$t('reports.display_all_events')"
      @click.native="event => event.stopPropagation()"
      @change="changeChecked($event)"
    />
  </div>
</template>
