<script setup>
import { defineProps, defineEmits } from 'vue';
import InfoIcon from '@/components/reports/icons/InfoIcon.vue';

const props = defineProps({
  isHaveAnnotationSettings: {
    type: Boolean,
    default: false,
  },
  isShowAnnotation: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['change-toggle-annotation']);

const changeToggleAnnotation = (event) => {
  emits('change-toggle-annotation', event);
};
</script>

<template>
  <div
    v-if="props.isHaveAnnotationSettings"
    class="flex"
  >
    <skif-checkbox
      id="checkbox_id"
      class="mr-2"
      show-label
      :value="props.isShowAnnotation"
      :label="$t('reports.show_annotation_on_click')"
      @click.native="event => event.stopPropagation()"
      @change="changeToggleAnnotation($event)"
    />
    <el-tooltip
      effect="dark"
      placement="bottom"
      :open-delay="500"
      :content="$t('reports.show_annotation_on_click_hint')"
      :offset="2"
      :visible-arrow="false"
    >
      <InfoIcon color="#A6AEB8" />
    </el-tooltip>
  </div>
</template>
