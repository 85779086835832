<script setup>
import { defineEmits } from 'vue';
const emits = defineEmits(['download-report', 'print-report']);

const downloadReport = () => {
  emits('download-report');
};

const printReport = () => {
  emits('print-report');
};

</script>

<template>
  <div class="flex flex-col">
    <!-- Скачать -->
    <a class="cursor-pointer font-bold text-annotationColor mb-1" @click="downloadReport">
      {{ $t('reports.load-report') }}
    </a>
    <!-- Печать -->
    <span class="cursor-pointer select-none font-bold text-annotationColor mb-1" @click="printReport">
      {{ $t('reports.print-report') }}
    </span>
  </div>
</template>
