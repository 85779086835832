<script setup>
import { ref, defineProps } from 'vue';
const props = defineProps({
  dialogColor: {
    type: Boolean,
    default: false,
  },
});

const dialogColor = ref(props.dialogColor);
const colorValue = ref('');

const checkedColor = () => {
  emit('change-color');
};
</script>

<template>
  <el-dialog
    custom-class="companiesColor"
    :title="$t('reports.show-color')"
    :visible.sync="dialogColor"
    :modal-append-to-body="false"
  >
    <color-panel
      v-model="colorValue"
      style="margin: 0 auto"
    />
    <div class="flex justify-end">
      <skif-button
        class="mt-1 text-[#5477a9]"
        style="width: fit-content; background: none;"
        variant="normal"
        @click="checkedColor"
      >
        {{ $t('btn.select') }}
      </skif-button>
    </div>
  </el-dialog>
</template>
